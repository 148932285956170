import type { ReactNode } from 'react'
import styled from '@emotion/styled'

import blocketBarThick from '../../assets/misc/blocket/blocket-colorbar-footer.svg'
import { currentBrand } from '../../brands'

const Wrapper = styled.div(({ theme }) => ({
  backgroundColor: theme.colors.bg.brandSecondary,
  color: theme.colors.text.onBrandSecondary,
  borderTopLeftRadius: theme.radii['2xl'],
  borderTopRightRadius: theme.radii['2xl'],
}))

const BlocketColorBar = styled.div({
  height: 22.5,
  backgroundImage: `url(${blocketBarThick.src})`,
  backgroundRepeat: 'repeat-x',
  backgroundSize: 'auto',
})

export function FooterWrapper({ children }: { children: ReactNode }) {
  if (currentBrand === 'blocket')
    return (
      <>
        <BlocketColorBar />
        {children}
      </>
    )
  return <Wrapper data-testid="global-page-footer">{children}</Wrapper>
}
