import type { CSSProperties } from 'react'
import { useEffect, useRef } from 'react'
import useScript from 'react-script-hook'
import { useBreakpointValue } from '@qasa/qds-ui'

import { BRAND_CONFIG } from '../../brands/brand-configurations'
import { AVAILABLE_LOCALES } from '../../translations/language-loader/language-utils'
import { useCurrentLocale } from '../../translations/use-current-locale'

const IFRAME_ALIGNMENT_MARGIN = 25

const MICRO_STAR_TEMPLATE_ID = '5419b732fbfb950b10de65e5'
const CAROUSEL_TEMPLATE_ID = '53aa8912dec7e10d38f59f36'

const Widget = 'div'

declare const window: {
  Trustpilot: LegitimateAny
} & Window

type TrustpilotWidgetProps = {
  variant: 'microStar' | 'carousel'
}
export function TrustpilotWidget({ variant }: TrustpilotWidgetProps) {
  const trustpilotId = BRAND_CONFIG.trustpilotId
  const locale = useCurrentLocale()
  const IFrameMargin = useBreakpointValue({ base: 0, md: IFRAME_ALIGNMENT_MARGIN }, { ssr: true })
  const [isLoading, error] = useScript({
    src: trustpilotId ? '//widget.trustpilot.com/bootstrap/v5/tp.widget.bootstrap.min.js' : null,
  })
  const currentLanguageCountryCode =
    AVAILABLE_LOCALES.find(({ languageCode }) => languageCode === locale)?.ietfCode || 'sv-SE'

  const templatesWithStyles: Record<
    TrustpilotWidgetProps['variant'],
    { 'data-template-id': string; 'data-style-height': string; style?: CSSProperties }
  > = {
    microStar: {
      'data-template-id': MICRO_STAR_TEMPLATE_ID,
      'data-style-height': '70px',
      //Hack-solution for aligning the iframe content with our footer content - Sahin Aslan 2021-06-07
      style: { marginLeft: `-${IFrameMargin}px`, width: '100%' },
    },
    carousel: {
      'data-template-id': CAROUSEL_TEMPLATE_ID,
      'data-style-height': '140px',
    },
  }

  // Create a reference to the <div> element which will represent the TrustBox
  const ref = useRef(null)
  useEffect(() => {
    if (window.Trustpilot && !isLoading) {
      window.Trustpilot.loadFromElement(ref.current, true)
    }
  }, [isLoading])

  if (!trustpilotId || isLoading || error) {
    return null
  }

  return (
    <Widget
      ref={ref} // We need a reference to this element to load the TrustBox in the effect.
      data-locale={currentLanguageCountryCode}
      data-businessunit-id={trustpilotId}
      data-style-width="100%"
      data-stars="4,5"
      data-theme="light"
      {...templatesWithStyles[variant]}
    />
  )
}
