import { Paragraph, Heading, Button, Stack } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { useEffect } from 'react'

import { usePreviousValue } from '../../hooks/use-previous-value'
import { useAdminState } from '../../data/admin-state'
import { useAuthContext } from '../../context/auth-context'
import { getPath } from '../../routing/get-path'
import { useRouter } from '../../vendor/next'

const Wrapper = styled(Stack)(({ theme }) => ({
  position: 'fixed',
  bottom: 0,
  left: 0,
  right: 0,
  margin: theme.spacing['5x'],
  maxWidth: theme.sizes['384'],
  backgroundColor: 'rgba(255, 215, 0, 0.8)',
  borderRadius: theme.radii.md,
  padding: theme.spacing['6x'],
  zIndex: theme.zIndices.toast,
  pointerEvents: 'none',
}))
const LogoutButton = styled(Button)({
  pointerEvents: 'all',
  opacity: '0.8',
})

export function AdminBox() {
  const { push } = useRouter()
  const { isAdminLoggedInAsUser, setAdminLoggedInAsUser } = useAdminState()
  const { authBody } = useAuthContext()
  const isPreviouslyLoggedInAsAdmin = usePreviousValue(isAdminLoggedInAsUser)
  const hasLoggedOut = isPreviouslyLoggedInAsAdmin && !isAdminLoggedInAsUser

  useEffect(() => {
    if (hasLoggedOut) {
      push(getPath('adminLoginAsUser'))
    }
  }, [hasLoggedOut, push])

  const handleLogout = () => setAdminLoggedInAsUser()

  if (!isAdminLoggedInAsUser) return null

  return (
    <Wrapper alignItems="center" gap={'2x'}>
      <Paragraph>
        {'You are logged in as '}
        <Heading as="span" size="2xs">
          {authBody?.private.email}
        </Heading>
      </Paragraph>
      <LogoutButton variant={'tertiary'} onClick={handleLogout}>
        {'Log out'}
      </LogoutButton>
    </Wrapper>
  )
}
