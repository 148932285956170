import { useTranslation } from 'react-i18next'
import { Link } from '@qasa/qds-ui'
import styled from '@emotion/styled'
import { showSchibstedCookieConsentDialog } from '@qasa/app'

import { BRAND_TOGGLES } from '../../brands/toggles'

const StyledLink = styled(Link)(({ theme }) => ({
  ...theme.typography.body.lg,
  textDecoration: 'none',
  paddingTop: theme.spacing['16x'],
  alignSelf: 'center',
  [theme.mediaQueries.mdUp]: {
    paddingTop: theme.spacing['12x'],
    alignSelf: 'flex-start',
  },
}))

type ExternalCookieSettingsLinkProps = {
  className?: string
  onClick?: () => void
}
export function ExternalCookieSettingsLink({ className, onClick }: ExternalCookieSettingsLinkProps) {
  const { t } = useTranslation('external_cookie_settings_link')

  const handleSourcepointButtonClick = () => {
    if (onClick) {
      onClick()
    }
    showSchibstedCookieConsentDialog()
  }
  if (BRAND_TOGGLES.usesSchibstedSourcepointClient) {
    return (
      <>
        {/* @ts-ignore - needed because 'as' property doesn't work on styled Link */}
        <StyledLink as="button" onClick={handleSourcepointButtonClick} className={className}>
          {t('cookie_settings')}
        </StyledLink>
      </>
    )
  }
  return null
}
